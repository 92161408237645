<template>
  <div>
    <div class="position-relative">
      <select
        class="form-select"
        :class="{'mb-4': selectedItem, 'custom-form-control-error': showError && !selectedItem}"
        @change="onSelectChange($event)"
      >
        <option :value="null" selected>Seleccione una opción</option>
        <option v-for="item in options" :key="item.key" :value="item.key">
          {{ item.label }}
        </option>
      </select>

      <ArrowBottomIcon class="form-select-arrow"/>
    </div>

    <div v-if="selectedItem && selectedItem.children.length && level <= visibleLevel">
      <!-- Renderiza recursivamente para los elementos hijos -->
      <NestedSelect :options="selectedItem.children" :showError="showError"
                    :key="childComponentKey"
                    :level="level+1"
                    :visible-level="visibleLevel"
                    @selected="onChildrenEmit"/>
    </div>
  </div>
</template>

<script>
import { ArrowBottomIcon } from 'wize-admin'

export default {
  name: "NestedSelect",
  props: {
    options: {
      type: Array,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
    visibleLevel: {
      type: Number,
      required: true,
    },
    showError: Boolean
  },
  components: {
    ArrowBottomIcon
  },
  data() {
    return {
      selectedItem: null,
      childComponentKey: 0,
    };
  },
  watch: {
    visibleLevel: function () {
      if (this.level === this.visibleLevel) {
        this.childComponentKey++
      }
    },
  },
  methods: {
    onChildrenEmit(data) {
      this.$emit('selected', data)
    },
    onSelectChange(e) {
      this.selectedItem = null;
      const selectedKey = e.target.value;
      this.selectedItem = this.options.find((item) => item.key === selectedKey);

      let index = this.options.findIndex(item => item.key === selectedKey)

      if (index >= 0) {
        this.options.forEach(item => item.selected = false)
        this.options[index].selected = true
        this.selectedItem = this.options[index]

        this.$emit('selected', {
          key: selectedKey,
          level: this.level,
        })
      } else {
        this.options.forEach(item => item.selected = false)
      }
    },
  },
};
</script>

<style scoped></style>
