<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Cargar pagos</h1>
    </div>

    <div>
      <b-card
        tag="article"
        no-body
        style="max-width: 640px"
        class="custom-card"
      >
        <b-card-header
          header-tag="section"
          class="custom-card-header d-flex justify-content-between align-items-center"
        >
          <h3>Carga de pagos</h3>

          <ButtonWithTooltip
            text="Descargar plantilla"
            @click="downloadTemplate"
            class="m-0"
            tooltip-variant="download-template"
          >
            <template slot="icon">
              <DownloadTableIcon />
            </template>
          </ButtonWithTooltip>
        </b-card-header>
        <b-card-body class="custom-card-body p-0">
          <form class="custom-form" @submit.prevent="save" novalidate>
            <div class="form-section">
              <simple-alert
                text="Seleccione el proyecto y periodo para poder cargar su archivo XLSX."
                type="light-gray"
              >
                <template slot="icon">
                  <WarningAlertIcon class="mr-2 alert-icon" />
                </template>
              </simple-alert>

              <div class="mt-4">
                <div class="form-group">
                  <label for="projectType">Proyecto</label>
                  <FormSelect
                    id="projectType"
                    v-model="projectTypeId"
                    :items="projectTypes"
                    defaultOption="Seleccione proyecto"
                    :showError="$v.projectTypeId.$error"
                    @change="setProjectTypeList()"
                  />
                  <FormError
                    v-if="$v.projectTypeId.$error && !$v.projectTypeId.required"
                    message="Periodo es requerido"
                  />
                </div>
                <div class="form-group">
                  <label for="prefix">Periodo *</label>
                  <input
                    id="prefix"
                    type="text"
                    v-model.trim="period"
                    autocomplete="off"
                    maxlength="11"
                    placeholder="202409-001"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error': $v.period.$error,
                    }"
                  />
                  <FormError
                    v-if="$v.period.$error && !$v.period.required"
                    message="Periodo es requerido"
                  />
                </div>
                <div class="form-group">
                  <label for="identifier">Identificador *</label>
                  <input
                    id="identifier"
                    type="text"
                    disabled
                    v-model.trim="identifier"
                    autocomplete="off"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error': $v.identifier.$error,
                    }"
                  />
                  <FormError
                    v-if="$v.identifier.$error && !$v.identifier.required"
                    message="Identificador es requerido"
                  />
                </div>
                <div class="form-group" v-if="projectTagsList.length">
                  <label for="prefix">Tags *</label>

                  <NestedSelect :options="projectTagsList" :showError="showTagsError" :level="1" @selected="setIdentifier($event)"
                                :visible-level="visibleLevel"
                  :key="nestedSelectorKey"/>

                  <FormError
                    message="La etiqueta es requerida"
                    v-if="showTagsError"
                  />
                </div>
              </div>

              <section class="file-container">
                <FileInput
                  class="w-100 mt-2"
                  id-file="file"
                  :acceptFile="['.xlsx']"
                  @onChange="onFileChange"
                />
                <FormError
                  v-if="$v.file.$error && !$v.file.required"
                  message="El archivo es requerido"
                />
              </section>

              <div class="form-group-buttons text-right">
                <router-link
                  :to="{ name: 'payments-batch-list' }"
                  class="button button-light"
                >
                  Cancelar
                </router-link>
                <button class="button button-primary" type="submit">
                  Procesar
                </button>
              </div>
              <div class="form-errors mt-3" v-if="errors.length > 0">
                <b
                  ><span class="error" v-if="errors.length > 0"
                    >Errores: {{ errors.length }}</span
                  ></b
                ><br />
                <span
                  v-for="(e, i) in errors"
                  class="file-error"
                  :key="`${i}-error`"
                  >{{ e }}<br
                /></span>
              </div>
            </div>
          </form>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  Util,
  FileInput,
  FormError,
  FormSelect,
  ButtonWithTooltip,
  SimpleAlert,
  DownloadTableIcon,
  WarningAlertIcon,
} from "wize-admin";

import { required } from "vuelidate/lib/validators";

import fileDownload from "js-file-download";
import { PaymentBatchService, ProjectTypeService } from "@/core/services";

import NestedSelect from "../Components/NestedSelect.vue";

export default {
  components: {
    SimpleAlert,
    FileInput,
    ButtonWithTooltip,
    FormError,
    FormSelect,
    DownloadTableIcon,
    WarningAlertIcon,
    NestedSelect,
  },
  name: "PaymentBatchUpload",
  data() {
    return {
      period: null,
      projectTypeId: null,
      identifier: '',
      file: null,
      errors: [],
      projectTypes: [],
      projectTagsList: [],
      showTagsError: false,
      nestedSelectorKey: 0,
      selectedTags: [],
      visibleLevel: 0,
    };
  },
  watch: {
    projectTypeId: function () {
      this.nestedSelectorKey++;
    },
  },
  methods: {
    async downloadTemplate() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await PaymentBatchService.downloadTemplate();
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    isAnyChildSelected(item) {
      if (!item.children || item.children.length === 0) {
        return item.selected;
      }
      return item.children.some(child => this.isAnyChildSelected(child));
    },
    async save() {
      this.showTagsError = false
      this.$v.$touch();

      if (this.projectTagsList.length) {
        // Validar que se hayan seleccionado todas las opciones de "tags"
        let selectedTag = this.projectTagsList.find(
          (item) => item.selected
        );

        if(!selectedTag) {
          this.showTagsError = true
          return
        }

        let anyChildSelected = this.isAnyChildSelected(selectedTag);
        this.showTagsError = !anyChildSelected;
      }

      if (this.$v.$anyError || this.showTagsError) {
        return;
      }

      await this.$store.dispatch("app/loading", true);
      this.errors = [];
      const data = {
        period: this.period,
        file: this.file,
        projectTypeId: this.projectTypeId,
        identifier: this.identifier,
      };
      try {
        const resp = await PaymentBatchService.uploadBatch(data);
        if (resp.payload.messages && resp.payload.messages.length > 0) {
          this.errors = resp.payload.messages;
          return;
        }
        Alert.success("Se cargaron los pagos correctamente");
        this.$router.push({ name: "payments-batch-list" });
      } catch (e) {
        this.errors = [];
        if (e.errors && e.errors.message) this.errors.push(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    onFileChange(files) {
      this.$v.file.$reset();
      if (Array.isArray(files)) {
        this.file = files[0];
      } else {
        this.file = files;
      }
    },
    setProjectTypeList() {
      let projectType = this.projectTypes.find(
        (item) => item.key === this.projectTypeId
      );

      if (projectType && projectType.tags) {
        this.projectTagsList = projectType.tags;
        this.setLevels(this.projectTagsList);
      } else {
        this.projectTagsList = [];
      }
    },
    setLevels(tags, currentLevel = 1){
      tags.forEach(tag => {
        tag.level = currentLevel;
        if (tag.children && tag.children.length > 0) {
          this.setLevels(tag.children, currentLevel + 1);
        }
      });
    },
    updateSelection(tags, level){
      tags.forEach(tag => {
        if (tag.level > level) {
          tag.selected = false;
        }
        if (tag.children && tag.children.length > 0) {
          this.updateSelection(tag.children, level);
        }
      });
    },
    setIdentifier(e) {
      this.visibleLevel = e.level;
      const existingIndex = this.selectedTags.findIndex(tag => tag.level === e.level);
      this.updateSelection(this.projectTagsList, e.level);
      if (existingIndex !== -1) {
        this.selectedTags[existingIndex] = { level: e.level, key: e.key };
      } else {
        this.selectedTags.push({ level: e.level, key: e.key });
      }
      this.selectedTags = this.selectedTags.filter(tag => tag.level <= e.level);
      this.identifier = this.selectedTags.map(tag => tag.key).join('-');
      this.showTagsError = false;
    }
  },
  validations: {
    period: { required },
    file: { required },
    projectTypeId: { required },
    identifier: { required },
  },
  async created() {
    const projectTypes = (await ProjectTypeService.listActive()).payload;
    projectTypes.forEach((b) =>{
        return this.projectTypes.push({
          key: b.id,
          label: b.description,
          paymentFrequency: b.paymentFrequency,
          tags: JSON.parse(b.tags),
        })
    }

    );
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/payment-batch-upload';
</style>
