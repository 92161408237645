<template>
  <b-modal
    v-model="showLocal"
    @hidden="hide"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    size="lg"
    id="rejected-voucher-modal"
  >
    <div class="modal-header d-flex justify-content-between align-items-center">
      <span class="modal-title">Motivo de rechazo</span>
      <b-button class="modal-close-icon" @click="hide" aria-label="Close">
        <i class="ri-close-large-line"></i>
      </b-button>
    </div>

    <section class="modal-body-container">
      <div class="text-center">
        <p><b>Se ha rechazado la factura por los siguientes motivos:</b></p>
        <p class="my-0" v-html="rejectReason"></p>
        <p>
          <b
            >Debe cargar una nota de crédito para liberar el ID de pago y poder
            subir una nueva factura.</b
          >
        </p>
      </div>
    </section>
  </b-modal>
</template>
<script>
import { ModalMixin } from "wize-admin";
import { required } from "vuelidate/lib/validators";

export default {
  name: "RejectedVoucherModal",
  props: {
    rejectReason: String,
  },
  data() {
    return {
      reasonId: null,
      reasons: [],
    };
  },
  mixins: [ModalMixin],
  methods: {
    hide() {
      this.$emit("hide");
    },
  },
  validations: {
    reasonId: { required },
  },
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/components/rejected-voucher-modal.styl"
</style>
