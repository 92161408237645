<template>
  <!-- @input="emitter" -->
  <draggable
    v-bind="dragOptions"
    tag="div"
    class="item-container mb-2"
    :list="list"
    :value="value"
  >
    <div class="item-group" :key="i" v-for="(el, i) in realValue">
      <div
        class="item d-flex justify-content-between w-100"
        :class="{ 'mb-2': !el.show }"
      >
        <span>{{ el.label }}</span>

        <button
          type="button"
          class="border-0 bg-transparent"
          @click="showOptions(el)"
        >
          <i class="ri-arrow-down-s-line" v-if="!el.show"></i>
          <i class="ri-arrow-up-s-line" v-else></i>
        </button>
      </div>

      <div class="options mb-2" v-if="el.show">
        <form class="custom-form">
          <div class="form-group">
            <label for="paymentType">Código *</label>
            <input
              id="paymentType"
              type="text"
              v-model="el.key"
              autocomplete="off"
              placeholder="Ingresar código"
              class="custom-form-control"
            />

            <!-- <FormError
              v-if="
                $v.paymentTypeForm.key.$error &&
                !$v.paymentTypeForm.key.required
              "
              message="Código es requerido"
            /> -->
          </div>

          <div class="form-group mb-0">
            <label for="paymentTypeDescription">Descripción *</label>
            <input
              id="paymentTypeDescription"
              type="text"
              v-model="el.label"
              autocomplete="off"
              placeholder="Ingresar descripción"
              class="custom-form-control"
            />

            <!-- <FormError
              v-if="
                $v.paymentTypeForm.label.$error &&
                !$v.paymentTypeForm.label.required
              "
              message="Descripción es requerida"
            /> -->
          </div>

          <div class="text-right mt-2">
            <a href="" class="text-danger d-inline-block mr-2" @click.prevent="removeAt(i)">Eliminar</a>
            <a href="" @click.prevent="cancel(el)">Cancelar</a>
          </div>
        </form>
      </div>

      <nested-draggable class="item-sub" :list="el.children" />
    </div>
  </draggable>
</template>

<script>
// import { FormError } from "wize-admin";
import draggable from "vuedraggable";

export default {
  name: "nested-draggable",
  data() {
    return {
      draft: null
    }
  },
  methods: {
    emitter(value) {
      this.$emit("input", value);
    },
    showOptions(item) {
      if(item.show) {
        item.show = false;
      } else {
        this.list.forEach((o) => (o.show = false));
        item.show = true;

        this.draft = JSON.parse(JSON.stringify(item))
      }
    },
    cancel(item) {
      if(item) {
        item.key = this.draft.key
        item.label = this.draft.label
        item.show = false
      }
    },
    removeAt(i) {
      this.list.splice(i, 1);
    }
  },
  components: {
    draggable,
    // FormError
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    // this.value when input = v-model
    // this.list  when input != v-model
    realValue() {
      return this.value ? this.value : this.list;
    },
  },
  props: {
    value: {
      required: false,
      type: Array,
      default: null,
    },
    list: {
      required: false,
      type: Array,
      default: null,
    },
  },
};
</script>

<style lang="stylus">
@import "../Styles/nested-draggable.styl";
</style>
