<template>
  <div class="page-container project-type-editor">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar proyecto" : "Nuevo proyecto" }}
      </h1>
    </div>
    <div>
      <b-card
        tag="article"
        no-body
        style="max-width: 640px"
        class="custom-card"
      >
        <b-card-header
          header-tag="section"
          class="card-header custom-card-header"
        >
          <h3>
            {{ editing ? "Datos del proyecto" : "Datos del nuevo proyecto" }}
          </h3>
        </b-card-header>
        <b-card-body class="custom-card-body p-0">
          <form class="custom-form" @submit.prevent="save" novalidate>
            <section class="form-section">
              <div class="form-group">
                <label for="description">Descripción *</label>
                <input
                  id="description"
                  type="text"
                  v-model.trim="form.description"
                  autocomplete="off"
                  maxlength="100"
                  placeholder="Ingresar descripción"
                  class="custom-form-control"
                  :class="{
                    'custom-form-control-error': $v.form.description.$error,
                  }"
                />
                <FormError
                  v-if="
                    $v.form.description.$error && !$v.form.description.required
                  "
                  message="Descripción es requerida"
                />
              </div>
              <div class="form-group">
                <label for="prefix">Prefijo *</label>
                <input
                  id="prefix"
                  type="text"
                  v-model.trim="form.prefix"
                  autocomplete="off"
                  maxlength="5"
                  placeholder="Ingresar prefijo"
                  class="custom-form-control"
                  :class="{
                    'custom-form-control-error': $v.form.prefix.$error,
                  }"
                />
                <FormError
                  v-if="$v.form.prefix.$error && !$v.form.prefix.required"
                  message="Prefijo es requerido"
                />
              </div>
              <div class="form-group">
                <label for="prefix">Supervisor</label>
                <input
                  id="prefix"
                  type="text"
                  v-model.trim="form.supervisor"
                  autocomplete="off"
                  placeholder="Ingresar correo electrónico de supervisor"
                  class="custom-form-control"
                  :class="{
                    'custom-form-control-error': $v.form.supervisor.$error,
                  }"
                />
                <FormError
                  v-if="$v.form.supervisor.$error && !$v.form.supervisor.email"
                  message="Correo electrónico de supervisor es requerido"
                />
              </div>

              <div class="form-group mb-0">
                <div class="row">
                  <div class="col-12">
                    <label for="paymentType">Tags de pago *</label>
                  </div>

                  <div class="col-md-5">
                    <div class="form-group">
                      <label for="paymentType">Código *</label>
                      <input
                        id="paymentType"
                        type="text"
                        v-model="paymentTypeForm.key"
                        autocomplete="off"
                        placeholder="Ingresar código"
                        class="custom-form-control"
                        :class="{
                          'custom-form-control-error':
                            $v.paymentTypeForm.key.$error &&
                            !$v.paymentTypeForm.key.required,
                        }"
                      />

                      <FormError
                        v-if="
                          $v.paymentTypeForm.key.$error &&
                          !$v.paymentTypeForm.key.required
                        "
                        message="Código es requerido"
                      />
                    </div>

                    <div class="form-group">
                      <label for="paymentTypeDescription">Descripción *</label>
                      <input
                        id="paymentTypeDescription"
                        type="text"
                        v-model="paymentTypeForm.label"
                        autocomplete="off"
                        placeholder="Ingresar descripción"
                        class="custom-form-control"
                        :class="{
                          'custom-form-control-error':
                            $v.paymentTypeForm.label.$error &&
                            !$v.paymentTypeForm.label.required,
                        }"
                      />

                      <FormError
                        v-if="
                          $v.paymentTypeForm.label.$error &&
                          !$v.paymentTypeForm.label.required
                        "
                        message="Descripción es requerida"
                      />
                    </div>

                    <div class="form-group mb-0 text-right">
                      <button
                        type="button"
                        class="button button-primary"
                        @click="addPaymentType()"
                      >
                        Agregar a la lista
                      </button>
                    </div>

                    <FormError
                        v-if="!$v.form.tags.$pending && $v.form.tags.$error"
                        message="Tags son requeridos"
                        class="mt-3"
                      />
                  </div>

                  <div class="col-md-7 mt-3 mt-md-0">
                    <div
                      class="project-type-no-results mt-2 py-2"
                      v-if="!form.tags.length"
                    >
                      <p class="label-description my-0">
                        Añadir elementos para ordenarlos
                      </p>
                    </div>

                    <div class="mt-2" v-else>
                      <nested-draggable :list="form.tags" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group-buttons text-right">
                <router-link
                  :to="{ name: 'config-project-type-list' }"
                  class="button button-light"
                >
                  {{ editing ? "Volver" : "Cancelar" }}
                </router-link>
                <button class="button button-primary" type="submit">
                  Guardar
                </button>
              </div>
            </section>
          </form>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { FormError, Alert } from "wize-admin";

import { required, email } from "vuelidate/lib/validators";
import { ProjectTypeService } from "@/core/services";

import NestedDraggable from "../Components/NestedDraggable.vue";

export default {
  components: {
    FormError,
    // draggable,
    NestedDraggable,
  },
  data() {
    return {
      paymentTypeForm: {
        key: null,
        label: null,
        children: [],
        show: false,
      },
      form: {
        description: null,
        prefix: null,
        supervisor: null,
        tags: [],
      },
      editing: false,
    };
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  methods: {
    async save() {
      const self = this;
      self.$v.form.$touch();
      if (self.$v.form.$error) return;

      try {
        this.$store.dispatch("app/loading", true);
        const data = { ...this.form, tags: JSON.stringify(this.form.tags) };
        let response;
        if (!this.editing) response = await ProjectTypeService.save(data);
        else
          response = await ProjectTypeService.update(
            this.$route.params.id,
            data
          );
        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "config-project-type-edit",
            params: { id: response.payload.id },
          });
          this.editing = true;
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await ProjectTypeService.get(id);
        const payload = response.payload;

        let tags = JSON.parse(payload.tags);
        tags.forEach((item) => (item.selected = false));

        this.form = { ...payload, tags: JSON.parse(payload.tags) };
        // this.form = { ...payload, tags: JSON.parse('[{"key":"1","label":"1","children":[{"key":"2","label":"2","children":[],"show":false}],"show":false},{"key":"3","label":"3","children":[{"key":"4","label":"4","children":[{"key":"5","label":"5","children":[],"show":false}],"show":false}],"show":false}]') };

        this.editing = true;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
        this.$router.push({ name: "config-project-type-list" });
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    addPaymentType() {
      const self = this;
      self.$v.paymentTypeForm.$touch();
      if (self.$v.paymentTypeForm.$error) return;

      let { key, label } = this.paymentTypeForm;

      this.form.tags.push({
        key,
        label,
        children: [],
        show: false,
        selected: false,
      });

      this.paymentTypeForm.key = null;
      this.paymentTypeForm.label = null;

      this.$v.paymentTypeForm.$reset();
    },
  },
  validations: {
    form: {
      description: { required },
      prefix: { required },
      supervisor: { email },
      tags: {
        required(value) {
          return Array.isArray(value) && value.length > 0;
        },
      },
    },
    paymentTypeForm: {
      key: { required },
      label: { required },
    },
  },
  mounted() {
    if (this.$route.params.id) this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus" scoped>
@import "../Styles/project-type-editor"
</style>
