import VouchersView from "../VouchersView.vue";
import VoucherList from "../Pages/VoucherList.vue";
import UploadVoucherEmptyPage from "../Pages/UploadVoucherEmptyPage.vue";

const routes = [
  {
    path: '/comprobantes',
    component: VouchersView,
    children: [
      {
        path: 'lista',
        name: 'voucher-list',
        roles: ['accounting'],
        component: VoucherList
      },
      {
        path: 'carga',
        name: 'voucher-upload-voucher-empty',
        component: UploadVoucherEmptyPage
      },
    ]
  },
]

export default routes
