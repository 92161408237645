<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Cargar abonos</h1>
    </div>

    <div>
      <b-card
        tag="article"
        no-body
        style="max-width: 640px"
        class="custom-card"
      >
        <b-card-header
          header-tag="section"
          class="custom-card-header d-flex justify-content-between align-items-center"
        >
          <h3>Carga de abonos</h3>
        </b-card-header>
        <b-card-body class="custom-card-body p-0">
          <form class="custom-form" @submit.prevent="save" novalidate>
            <div class="form-section">
              <simple-alert
                text="Seleccione los siguientes campos para poder cargar sus archivos."
                type="light-gray"
              >
                <template slot="icon">
                  <WarningAlertIcon class="mr-2 alert-icon" />
                </template>
              </simple-alert>

              <div class="custom-form mt-4">
                <div class="form-group">
                  <label for="payrollNumber">N° de planilla *</label>
                  <input
                    id="payrollNumber"
                    type="text"
                    v-model.trim="payrollNumber"
                    autocomplete="off"
                    maxlength="50"
                    placeholder="N° de planilla"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error': $v.payrollNumber.$error,
                    }"
                  />
                  <FormError
                    v-if="$v.payrollNumber.$error && !$v.payrollNumber.required"
                    message="Número de planilla es requerido"
                  />
                </div>
                <div class="form-group">
                  <label for="payrollDesc">Descripción de planilla</label>
                  <input
                    id="payrollDesc"
                    type="text"
                    v-model.trim="payrollDesc"
                    autocomplete="off"
                    maxlength="100"
                    placeholder="Descripción de planilla"
                    class="custom-form-control"
                  />
                </div>
              </div>
              <section class="file-container mt-4">
                <label for="records">Cargar excel de abonos</label>
                <FileInput
                  class="w-100 mt-2"
                  id-file="records"
                  :acceptFile="['.xlsx']"
                  @onChange="onFileChange"
                />
              </section>
              <section class="file-container mt-4">
                <label for="records">Cargar constancias</label>
                <FileInput
                  class="w-100 mt-2"
                  :multiple="true"
                  id-file="records"
                  :acceptFile="['.pdf']"
                  @onChange="onRecordsChange"
                />
              </section>

              <div class="form-group-buttons text-right">
                <router-link
                  :to="{ name: 'payments-made-batch-list' }"
                  class="button button-light"
                >
                  Cancelar
                </router-link>
                <button class="button button-primary" type="submit">
                  Procesar
                </button>
              </div>
              <div class="form-errors mt-3" v-if="errors.length > 0">
                <b
                  ><span class="error" v-if="errors.length > 0"
                    >Errores: {{ errors.length }}</span
                  ></b
                ><br />
                <span
                  v-for="(e, i) in errors"
                  class="file-error"
                  :key="`${i}-error`"
                  >{{ e }}<br
                /></span>
              </div>
            </div>
          </form>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  FileInput,
  FormError,
  SimpleAlert,
  WarningAlertIcon,
} from "wize-admin";

import { required } from "vuelidate/lib/validators";

import { PaymentMadeBatchService } from "@/core/services";

export default {
  components: {
    SimpleAlert,
    FileInput,
    FormError,
    WarningAlertIcon,
  },
  name: "PaymentBatchUpload",
  data() {
    return {
      payrollNumber: null,
      payrollDesc: null,
      records: [],
      errors: [],
      file: null,
    };
  },
  methods: {
    async save() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      await this.$store.dispatch("app/loading", true);
      this.errors = [];
      const data = {
        payrollNumber: this.payrollNumber,
        payrollDesc: this.payrollDesc,
      };
      if (this.records && this.records.length > 0) data.records = this.records;
      data.file = this.file;
      try {
        Object.keys(data).forEach((key) => {
          if (data[key] === null || typeof data[key] === "undefined") {
            delete data[key];
          }
        });

        const resp = await PaymentMadeBatchService.uploadBatch(data);
        if (resp.payload.messages && resp.payload.messages.length > 0) {
          this.errors = resp.payload.messages;
          return;
        }
        Alert.success("Se está procesando la carga de abonos.");
        this.$router.push({ name: "payments-made-batch-list" });
      } catch (e) {
        this.errors = [];
        if (e.errors && e.errors.message) this.errors.push(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    onFileChange(files) {
      if (Array.isArray(files)) {
        this.file = files[0];
      } else {
        this.file = files;
      }
    },
    onRecordsChange(files) {
      if (Array.isArray(files)) {
        this.records = files;
      } else {
        this.records = [files];
      }
    },
  },
  validations: {
    payrollNumber: { required },
  },
  async created() {},
};
</script>

<style lang="stylus" scoped>
@import '../Styles/payment-batch-upload';
</style>
